import React, { createContext, useReducer, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { alertActions } from "./alertStore.actions";
import {
  AlertActions,
  AlertState,
  alertStateReducer,
  initialState,
} from "./alertStore.reducer";
import { CustomIcon } from "components/icon/customIcon.component";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { H4, Text } from "components/Typography/text.styled";

export type AlertDispatch = (action: AlertActions) => void;

const AlertStateContext = createContext<AlertState | undefined>(undefined);
const AlertDispatchContext = createContext<AlertDispatch | undefined>(
  undefined
);

export const AlertStoreProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(alertStateReducer, initialState);

  return (
    <AlertStateContext.Provider value={state}>
      <AlertDispatchContext.Provider value={dispatch}>
        <Modal
          open={state.show}
          onClose={() => {
            dispatch({ type: "HIDE_ALERT" });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalBody>
            {state.icon && (
              <CustomIcon
                name={state.icon}
                size="150px"
                color={Color.SeaBlue400}
              />
            )}
            <H4 fontSize={FontSize.H4} style={{ margin: 0 }}>
              {state.title}
            </H4>
            <Text fontSize={FontSize.Large}>{state.message}</Text>
            <ButtonStyled
              onClick={() => {
                dispatch({ type: "HIDE_ALERT" });
              }}
              backgroundColor={Color.BurntSienna}
              color={Color.White}
            >
              {t("General.Ok")}
            </ButtonStyled>
          </ModalBody>
        </Modal>
        {children}
      </AlertDispatchContext.Provider>
    </AlertStateContext.Provider>
  );
};
export const useAlertStore = () => {
  const state = useContext(AlertStateContext);
  const dispatch = useContext(AlertDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error("useAlertStore must be used within a AlertStoreProvider");
  }

  const actions = alertActions(dispatch, state);

  return [state, actions, dispatch] as const;
};

const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 270px;
  width: 50%;
  max-width: 370px;
  padding: 20px 20px 30px;
  border-radius: 8px;
  background-color: ${Color.White};
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);

  p {
    margin-bottom: 30px;
  }
`;
