import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { SmallImage } from "../gig/gigs.styled";
import { Text } from "components/Typography/text.styled";
import { OfferWorkerCell} from "./gigDetail.styled";
import { ApplicantImageWrapper } from "../gigApplicants/gigApplicants.styled";
import { getWorkerImg } from "services/firebase/firebase.service";
import { ApplicationStatus } from "model/Application";

type Props = {
  offerList: {
    workerId: number;
    rate: number;
    usingOfferRate: boolean;
    type: "favorite" | "workpool";
  }[];
  setOfferList: React.Dispatch<
    React.SetStateAction<
      {
        workerId: number;
        rate: number;
        usingOfferRate: boolean;
        type: "favorite" | "workpool";
      }[]
    >
  >;
  firstName: string | null;
  lastName: string | null;
  workerId: number;
  firebaseId: string;
  hourlyRate: number;
  offerRate: number;
  useOfferRate: boolean;
  type: "favorite" | "workpool";
  disabled: boolean;
  status?: ApplicationStatus;
};

export const OfferWorkerListItem: React.FC<Props> = ({
  firstName,
  lastName,
  workerId,
  firebaseId,
  offerList,
  setOfferList,
  hourlyRate,
  offerRate,
  useOfferRate,
  type,
  disabled,
  status,
}) => {
  const { t } = useTranslation();
  const [workerImage, setWorkerImage] = useState<string>("");
  const [selected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    if (offerList && workerId) {
      const chosen = offerList.find(
        (offer) => offer.workerId === workerId && offer.type === type
      );
      if (chosen) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [offerList, workerId]);

  useEffect(() => {
    getWorkerImg(firebaseId).then((res) => {
      setWorkerImage(res);
    });
  }, [workerId]);

  return (
    <>
      <OfferWorkerCell
        disabled={disabled}
        onClick={(pressedId) => {
          if (!disabled) {
            if (
              offerList.find(
                (chosen) => chosen.workerId === workerId && chosen.type !== type
              )
            ) {
              setOfferList([
                ...offerList.filter((user) => user.workerId !== workerId),
                {
                  workerId: workerId,
                  rate: !hourlyRate ? offerRate : hourlyRate,
                  type: type,
                  usingOfferRate: useOfferRate || !hourlyRate ? true : false,
                },
              ]);
            } else if (
              offerList.find(
                (chosen) => chosen.workerId === workerId && chosen.type === type
              )
            ) {
              setOfferList(
                offerList.filter((user) => user.workerId !== workerId)
              );
            } else {
              setOfferList([
                ...offerList,
                {
                  workerId: workerId,
                  rate: !hourlyRate ? offerRate : hourlyRate,
                  type: type,
                  usingOfferRate: useOfferRate || !hourlyRate ? true : false,
                },
              ]);
            }
          }
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <ApplicantImageWrapper>
            {workerImage ? (
              <SmallImage src={workerImage} />
            ) : (
              <CustomIcon
                name="person"
                size="100%"
                color={Color.SeaBlue600}
                padding="10px"
              />
            )}
          </ApplicantImageWrapper>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Standard}
            style={{ marginLeft: "8px" }}
          >{`${firstName} ${lastName}`}</Text>
          {hourlyRate !== 0 && (
            <Text
              color={
                useOfferRate || !selected ? Color.SeaBlue500 : Color.BurntSienna
              }
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Small}
              style={{ marginLeft: 8 }}
              backgroundColor={
                useOfferRate || !selected ? Color.SeaBlue200 : Color.Positive
              }
              padding
            >
              {`${hourlyRate} ${t("FindWorker.HourlyRateWithCurrency")}`}
            </Text>
          )}
        </div>
        {!disabled && (
          <CustomIcon
            name="checkmark"
            size="27px"
            color={Color.White}
            backgroundColor={selected ? Color.ModernGreen : Color.White}
            padding="2px"
            style={{
              border: `2px solid ${Color.ModernGreen}`,
              flexShrink: 0,
              marginLeft: "15px",
            }}
          />
        )}
        {disabled && status && <Text>{status}</Text>}
      </OfferWorkerCell>
    </>
  );
};
