import { CompanyGigExperienceDto } from "./GigExperience";
import { ShiftDto, ShiftRequestDto } from "./Shift";

export enum GigStatus {
  CREATED = "Created",
  IN_PROGRESS = "InProgress",
  CLOSED = "Closed",
}

export type CompanyGigUserDto = {
  id: number;
  firebaseId: string | null;
  firstName: string | null;
  lastName: string | null;
  address: string | null;
  postalCode: string | null;
  city: string | null;
  countryId: number | null;
  description: string | null;
  paymentMethod?: PaymentMethod;
};

export type CompanyGigDto = {
  id: number;
  description: string | null;
  location: string | null;
  companyId: number;
  minRate: number;
  maxRate: number;
  role: string;
  type: GigType;
  skills: CompanyGigSkillDto[];
  amountOfWorkers: number;
  status: GigStatus;
  shifts: ShiftDto[];
  workers: CompanyGigUserDto[];
  gigExperiences: CompanyGigExperienceDto[];
  resolution: GigResolution;
  paymentType: PaymentMethod;
  parentGigId? : number;
  workerIds?: [];
};

export type CompanyGigRequestDto = {
  id?: number;
  description: string | null;
  minRate: number;
  maxRate: number;
  location: string | null;
  role: string;
  type?: GigType;
  skills: CompanyGigSkillRequestDto[];
  amountOfWorkers: number;
  shifts: ShiftRequestDto[];
  status?: GigStatus;
  resolution?: GigResolution;
  paymentType?: PaymentMethod;
  parentGigId? : number;
};

export type CompanyGigSkillDto = {
  id: number;
  skill: string;
  experience: number;
  gigId: number;
  deleteEntry: boolean;
};

export type CompanyGigSkillRequestDto = {
  id?: number;
  skill: string;
  experience: number;
  gigId?: number;
  deleteEntry?: boolean;
};

export type OfferGigRequest = {
  companyId: number;
  workerId: number;
  gigId: number;
  rate: number;
};

export type OfferGigResponse = {
  companyId: number;
  workerId: number;
  gigId: number;
  rate: number;
  applicationId: number;
};

export enum GigResolution {
  DONE = "Done",
  WITHDRAWN = "Withdrawn",
  NOT_FILLED = "NotFilled",
  CANCELLED = "Cancelled",
}

export enum GigType {
  PUBLIC = "Public",
  WORKPOOL = "Workpool",
  HIDDEN = "Hidden",
  STAFFING = "Staffing",
}

export enum PaymentMethod {
  NONE = "None",
  SIMFLEXITY_PAY = "SimflexityPay",
}

export type GigRating = {
  gigId: number;
  companyId: number;
  workerId: number;
  firstName: string;
  lastName: string;
  message: string;
  rating: number;
};

export type GigCostDto = {
  companyId: number;
  gigType: GigType;
  startTime: string;
  endTime: string;
  breakStartTime: string | undefined;
  breakEndTime: string | undefined;
  hourlyRate: number;
  workerId: number | null;
};

export type GigCosts = {
  grossSalary: number;
  obAmount: number;
  socialFee: number;
  simflexityFee: number;
  totalCost: number;
};
