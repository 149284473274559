import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChronoUnit, Instant, LocalDateTime } from "@js-joda/core";
import { CompanyGigDto } from "model/Gig";
import { ShiftDto } from "model/Shift";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { addLeadingZero, calculateBreakTime, getWorkHours } from "utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  ShiftUl,
  DropDownRow,
  Row,
  TimeAndDateContainer,
  VerticalDivider,
  TotalTimeContainer,
  TotalBrakeContainer,
} from "./gigDetail.styled";
import moment from "moment";

type Props = {
  gigData: CompanyGigDto;
};

export const GigDetailShifts: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const [shiftsOpen, setShiftsOpen] = useState(false);

  const renderShift = (shift: ShiftDto, index: number) => {
    const startTime = LocalDateTime.ofInstant(Instant.parse(shift.startTime));
    const endTime = LocalDateTime.ofInstant(Instant.parse(shift.endTime));

    const shiftMinutes = startTime.until(endTime, ChronoUnit.MINUTES);
    const shiftHours = Math.floor(shiftMinutes / 60);

    const shiftTime = `${shiftHours ? shiftHours + "h" : ""} ${
      shiftMinutes % 60 ? (shiftMinutes % 60) + "min" : ""
    }`;

    const breakTimeStart =
      shift.breakStartTime &&
      LocalDateTime.ofInstant(
        Instant.parse(shift.breakStartTime) //change to start break
      );
    const breakTimeEnd =
      shift.breakEndTime &&
      LocalDateTime.ofInstant(Instant.parse(shift.breakEndTime));

    const breakTimeStartString =
      breakTimeStart &&
      breakTimeStart.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();

    const breakTimeEndString =
      breakTimeEnd &&
      breakTimeEnd.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();

    return (
      <>
        <Row key={shift.id}>
          <TimeAndDateContainer>
            <Text
              color={Color.MidnightBlue}
              fontFamily={FontFamily.MontserratRegular}
              fontSize={FontSize.Standard}
              style={{ margin: 0, padding: 0 }}
            >
              {startTime.dayOfMonth()}/{startTime.monthValue()}
            </Text>
            <Text color={Color.SeaBlue600}>
              {`${addLeadingZero(startTime.hour())}:${addLeadingZero(
                startTime.minute()
              )} - ${addLeadingZero(endTime.hour())}:${addLeadingZero(
                endTime.minute()
              )}`}
            </Text>
          </TimeAndDateContainer>
          <VerticalDivider />
          <TotalTimeContainer>
            <Text
              style={{ margin: 0, padding: 0 }}
              fontSize={FontSize.Small}
              color={Color.SeaBlue600}
            >
              {t("GigsCompany.TotalTime")}
            </Text>
            <Text
              color={Color.MidnightBlue}
              fontFamily={FontFamily.MontserratRegular}
            >
              {shiftTime}
            </Text>
          </TotalTimeContainer>
          <TotalBrakeContainer>
            <Text
              style={{ margin: 0, padding: 0 }}
              fontSize={FontSize.Small}
              color={Color.SeaBlue600}
            >{`${t("GigsWorker.MinBreak")}${":"}`}</Text>
            <Text>
              {breakTimeStartString && breakTimeEndString
                ? `${calculateBreakTime(
                    breakTimeStartString,
                    breakTimeEndString,
                    moment(
                      LocalDateTime.ofInstant(
                        Instant.parse(shift.startTime)
                      ).toString()
                    ),
                    moment(
                      LocalDateTime.ofInstant(
                        Instant.parse(shift.endTime)
                      ).toString()
                    )
                  )} ${t("GigsWorker.MinutesUnpaidBreak")}`
                : "0" + t("GigsWorker.MinutesUnpaidBreak")}
            </Text>
          </TotalBrakeContainer>
        </Row>
      </>
    );
  };

  return (
    <>
      <DropDownRow
        onClick={() => {
          setShiftsOpen(!shiftsOpen);
        }}
      >
        <Text fontFamily={FontFamily.MontserratSemiBold}>
          {t("GigsCompany.Shifts")} ({gigData.shifts.length} {"st"})
        </Text>
        <CustomIcon
          name={shiftsOpen ? "upward_arrow" : "downward_arrow"}
          size="24px"
          color={Color.BurntSienna}
          style={{ marginTop: 10 }}
        />
      </DropDownRow>
      {shiftsOpen && (
        <>
          <Row>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("GigsCompany.TotalTime")}
            </Text>
            <Text fontSize={FontSize.Standard}>
              {getWorkHours(t, ...gigData.shifts)}
            </Text>
          </Row>
          <Row>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("GigsCompany.Type")}
            </Text>
            <Text fontSize={FontSize.Standard}>
              {t(`GigsCompany.${gigData.type}`)}
            </Text>
          </Row>
          <Row>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("GigsCompany.Shifts")}
            </Text>
            <Text fontSize={FontSize.Standard}>
              {}
            </Text>
          </Row>
          <ShiftUl>
            {gigData.shifts.map((shift, index) => {
              return renderShift(shift, index);
            })}
          </ShiftUl>
        </>
      )}
    </>
  );
};
