import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { LabelStyled } from "components/form/inputs/inputs.styled";
import { Text, H4 } from "components/Typography/text.styled";
import { SettingsSelect } from "components/form/select/select.styled";

import {
  SettingsDesktopLayout,
  SideBarStyled,
  SettingsMenuStyled,
  SettingsListStyled,
  SettingsCategoryContainer,
  SettingsPageContainer,
  TermsContainer,
} from "./companySettingsDesktopView.styled";
import i18n from "services/translation/i18n";
import { TermsAndPrivacy } from "../companySubscription/termsAndPrivacy.component";

type Props = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  children: JSX.Element;
};

export const CompanySettingsDesktopView: React.FC<Props> = ({
  setPage,
  children,
}) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  return (
    <SettingsDesktopLayout>
      <SideBarStyled>
        <SettingsMenuStyled>
          <H4 fontFamily={FontFamily.LeagueSpartanBold}>
            {t("General.Settings")}
          </H4>
          <SettingsListStyled>
            <SettingsCategoryContainer>
              <LabelStyled>{t("ProfileCompany.Company")}</LabelStyled>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=profile`);
                }}
              >
                <CustomIcon
                  name="person"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />
                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.Profile")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=details`);
                }}
              >
                <CustomIcon
                  name="document"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.Details")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=users`);
                }}
              >
                <CustomIcon
                  name="person"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyUsers.CompanyUsers")}
                </Text>
              </SettingsSelect>      
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=Ob`);
                }}
              >
                <CustomIcon
                  name="clock"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.OB")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=Subscription`);
                }}
              >
                <CustomIcon
                  name="wallet"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.Subscription")}
                </Text>
              </SettingsSelect>
              <SettingsSelect
                onClick={() => {
                  setPage(1);
                  history.push(`${path}?page=StampClock`);
                }}
              >
                <CustomIcon
                  name="clock"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />

                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("ProfileCompany.StampClock")}
                </Text>
              </SettingsSelect>
            </SettingsCategoryContainer>
            <SettingsCategoryContainer>
              <LabelStyled>{t("General.Simflexity")}</LabelStyled>
              <SettingsSelect
                onClick={() => {
                  const lang = i18n.language;
                  window.open(
                    `https://www.simflexity.com/${
                      lang.includes("en") ? "en" : lang
                    }/contact`
                  );
                }}
              >
                <CustomIcon
                  name="mail"
                  size="48px"
                  padding="10px"
                  color={Color.BurntSienna}
                />
                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("General.ContactSupport")}
                </Text>
              </SettingsSelect>
            </SettingsCategoryContainer>
          </SettingsListStyled>
        </SettingsMenuStyled>
        <TermsContainer>
          <TermsAndPrivacy />
        </TermsContainer>

      </SideBarStyled>
      <SettingsPageContainer>{children}</SettingsPageContainer>
    </SettingsDesktopLayout>
  );
};
