import styled, { css } from "styled-components";
import { Color } from "config/colors";
import { FontFamily } from "config/font";

type Props = {
  isDisabled?: boolean;
  smallerHeight?: boolean;
};

type OptionsProps = {
  isVisible: boolean;
  maxHeight?: number;
};

export const Wrapper = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;

   {
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        pointer-events: none;
      `}
  }
`;

export const Select = styled.div<Props>`
  height: ${(props) => (props.smallerHeight ? "30px" : "48px")};
  width: fit-content;
  padding-left: 8px;
  display: flex;
  align-items: center;
  font-family: ${FontFamily.LeagueSpartanBold};
  border-radius: 10px;
  color: ${Color.BurntSienna};
  cursor: pointer;

   {
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: 0.5;
      `}
  }
  &:hover {
    color: ${Color.Disabled};
  }
`;

export const SelectHeader = styled.div<Props>`
  display: flex;

   {
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: 0.5;
      `}
  }
`;

export const Options = styled.div<OptionsProps & Props>`
   {
    ${({ isVisible, maxHeight, isDisabled }) => css`
      display: ${isVisible ? "block" : "none"};
      max-height: ${maxHeight ? maxHeight + "px" : "none"};

      button {
        opacity: ${!isDisabled ? "unset" : "0.5"};
      }
    `}
  }

  position: absolute;
  top: 48px;
  min-width: 265px;
  overflow-y: auto;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
  border-radius: 8px;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
