import React, { useEffect, useState } from "react";
import { CustomLink, Text } from "components/Typography/text.styled";
import { WorkpoolWorker } from "model/Workpool";

import { useTranslation } from "react-i18next";
import {
  getWorkerImg,
  getWorkerVideo,
} from "services/firebase/firebase.service";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { useHistory } from "react-router-dom";
import { Modal } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { LabelStyled } from "components/form/inputs/inputs.styled";
import {
  ProfileGrid,
  WorkerImage,
  VideoContainer,
  VideoPresentation,
  VideoWrapper,
  Cell,
  ProfileDiv,
  DividerLine,
  ImageContainer,
  ModalBody,
  ButtonContainer,
  EditButtonContainer,
  LabelContainer,
  InfoContainer,
} from "./workpoolWorkerDetails.styled";
import { CompanyFavoriteDto } from "model/Favorite";
import { WorkpoolWorkerSettings } from "./workpoolWorkerSettings";

type Props = {
  workerData: WorkpoolWorker;
};

export const WorkpoolWorkerDetails: React.FC<Props> = ({ workerData }) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [companyState, companyActions] = useCompanyStore();
  const [, workpoolActions] = useWorkpoolStore();

  const [workerImage, setWorkerImage] = useState("");
  const [workerVideo, setWorkerVideo] = useState<string | null>(null);
  const [favorited, setFavorited] = useState<CompanyFavoriteDto | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const history = useHistory();

  const removeFromFavorites = () => {
    if (favorited && companyState.company) {
      companyActions
        .removeCompanyFavorite(companyState.company?.id, favorited.id)
        .then(() => {
          setModalOpen(false);
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert",
              title: t("Alerts.OhNo"),
              message: t("General.ErrorOccurred"),
            },
          });
        });
    }
  };

  const addToFavorites = () => {
    if (companyState.company) {
      companyActions
        .addCompanyFavorite(companyState.company?.id, workerData.worker.id)
        .then()
        .catch((err: any) => {
          if (err.response.data === "Worker does not have a valid SSN") {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("ApplicationsCompany.WorkerDoesNotHaveAValidSSN"),
              },
            });
          }
        });
    }
  };

  useEffect(() => {
    getWorkerImg(workerData.worker.firebaseId).then((res) => {
      setWorkerImage(res);
    });

    getWorkerVideo(workerData.worker.firebaseId)
      .then((res) => setWorkerVideo(res))
      .catch(() => {
        setWorkerVideo(null);
      });

    const matching = companyState.favorites.find((favoritedWorker) => {
      return favoritedWorker.workerId === workerData.worker.id;
    });
    if (matching) {
      setFavorited(matching);
    } else {
      setFavorited(null);
    }
  }, [workerData.worker, companyState.favorites]);

  const updateEmployeeStatus = (updateStatus: "Verify" | "Reject") => {
    if (companyState.company) {
      if (updateStatus === "Verify") {
        workpoolActions
          .verifyEmployee(companyState.company.id, workerData)
          .then(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("CompanyGroup.Verified"),
                message: t("CompanyGroup.ApplicantHasBeenVerified"),
              },
            });
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("CompanyGroup.ApplicationCouldNotBeUpdated"),
              },
            });
          });
      } else if (updateStatus === "Reject") {
        workpoolActions
          .deleteEmployee(companyState.company.id, workerData.worker.id)
          .then(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("CompanyGroup.Rejected"),
                message: t("CompanyGroup.ApplicationHasBeenRejected"),
              },
            });
            history.push("../work-pool");
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("ApplicationsCompany.ApplicationCouldNotBeUpdated"),
              },
            });
          });
      }
    }
  };

  const renderModalBody = () => {
    return (
      <>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyFavorite.AreYouSureRemoveFavorite")}
        </Text>
        <ButtonContainer>
          <ButtonStyled
            backgroundColor={Color.Disabled}
            color={Color.SeaBlue500}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {t("General.Abort")}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.Destructive}
            onClick={() => removeFromFavorites()}
          >
            {t("CompanyFavorite.Remove")}
          </ButtonStyled>
        </ButtonContainer>
      </>
    );
  };

  console.log(workerData.worker);

  return (
    <div>
      <CustomLink
        onClick={() => history.goBack()}
        color={Color.Destructive}
        text-align="right"
      >
        {t("General.Back")}
      </CustomLink>

      <ProfileGrid>
        <ProfileDiv>
          <ImageContainer>
            {workerImage ? (
              <WorkerImage src={workerImage} />
            ) : (
              <CustomIcon
                className="icon"
                square
                width="250px"
                name="person"
                size="190px"
                color={Color.SeaBlue500}
                backgroundColor={Color.SeaBlue300}
                padding="10px"
              />
            )}
            {favorited ? (
              <CustomIcon
                className="icon"
                name="favorites-active"
                size="48px"
                color={Color.White}
                backgroundColor={Color.BurntSienna}
                padding="10px"
                onClick={() => setModalOpen(true)}
              />
            ) : (
              <CustomIcon
                className="icon"
                name="favorites-inactive"
                size="48px"
                color={Color.White}
                backgroundColor={Color.BurntSienna}
                padding="10px"
                onClick={addToFavorites}
              />
            )}
          </ImageContainer>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {workerData.worker.firstName} {workerData.worker.lastName}
          </Text>
          <Text
            color={Color.SeaBlue600}
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {workerData.worker.city}
          </Text>
          {workerData.worker.stats &&
          workerData.worker.stats.some(
            (stat: any) => stat.stat === "Rating"
          ) ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CustomIcon
                  name="star"
                  color={Color.BurntSienna}
                  size="20px"
                  style={{ marginRight: "4px" }}
                />
                <Text
                  color={Color.MidnightBlue}
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                >
                  {workerData.worker.stats &&
                  workerData.worker.stats.some(
                    (stat: any) => stat.stat === "Rating"
                  )
                    ? workerData.worker.stats
                        .find((stat: any) => stat.stat === "Rating")
                        .value.toFixed(1)
                    : 0}
                  /5
                </Text>
                <Text
                  color={Color.SeaBlue500}
                  fontFamily={FontFamily.MontserratRegular}
                  fontSize={FontSize.Standard}
                  style={{ marginLeft: "4px" }}
                >
                  {workerData.worker.stats &&
                  workerData.worker.stats.some(
                    (stat: any) => stat.stat === "AmountOfRatings"
                  )
                    ? "(" +
                      workerData.worker.stats
                        .find((stat: any) => stat.stat === "AmountOfRatings")
                        .value.toFixed(0) +
                      ")"
                    : "(" + 0 + ")"}
                </Text>
              </div>
              <Text color={Color.SeaBlue500}>{t("ProfileWorker.Rating")}</Text>
            </div>
          ) : (
            <Text color={Color.SeaBlue500}>
              {t("ProfileWorker.NoRatingsYet")}
            </Text>
          )}
          <VideoContainer>
            <LabelStyled>{t("ProfileWorker.VideoPresentation")}</LabelStyled>
            {workerVideo ? (
              <VideoWrapper>
                <VideoPresentation controls src={workerVideo} />
              </VideoWrapper>
            ) : (
              <Text color={Color.SeaBlue400}>
                {t("ProfileWorker.NoVideoPresentation")}
              </Text>
            )}
          </VideoContainer>
          <InfoContainer>
            <LabelStyled>{t("ProfileWorker.PersonalNumber")}</LabelStyled>
            <Text color={Color.PitchBlack}>{workerData.worker.ssn}</Text>
          </InfoContainer>
          <InfoContainer>
            <LabelStyled>{t("ProfileWorker.PhoneNumber")}</LabelStyled>
            <Text color={Color.PitchBlack}>
              {"+" + workerData.worker.phoneNumber}
            </Text>
          </InfoContainer>
          <InfoContainer>
            <LabelStyled>{t("ProfileWorker.Address")}</LabelStyled>
            <Text color={Color.PitchBlack}>{workerData.worker.address}</Text>
            <Text color={Color.PitchBlack}>
              {`${workerData.worker.postalCode} ${workerData.worker.city}`}
            </Text>
          </InfoContainer>
          <InfoContainer>
            <LabelStyled>{t("ProfileWorker.Email")}</LabelStyled>
            <Text color={Color.PitchBlack}>{workerData.worker.email}</Text>
          </InfoContainer>
          {/*UNCOMMENT WHEN SKILLS IS ON WORKER OBJECT*/}
          {/* {worker.workerSkills.length !== 0 && (
            <ProfileDetailsContainer>
              <LabelStyled>{t("ProfileWorker.Skills")}</LabelStyled>
              <SkillsCell>
                {favorite.workerSkills.map((skill, index) => (
                  <SkillWrapper key={index}>
                    <Text
                      fontSize={FontSize.Standard}
                      color={Color.MidnightBlue}
                      fontFamily={FontFamily.MontserratSemiBold}
                    >
                      {t(skill.skill, { ns: "skills" })}
                    </Text>
                      {skill.experience === 0
                        ? `3-6 ${t("ProfileWorker.Months")}`
                        : skill.experience < 2
                        ? `1 ${t("ProfileWorker.Years")}`
                        : skill.experience < 3
                        ? `${skill.experience} ${t("ProfileWorker.Years")}`
                        : `+${skill.experience} ${t("ProfileWorker.Years")}`}
                  </SkillWrapper>
                ))}
              </SkillsCell>
            </ProfileDetailsContainer>
          )} */}
        </ProfileDiv>
        <DividerLine />
        <Cell>
          <LabelStyled>{t("ProfileWorker.Bio")}</LabelStyled>
          <Text fontSize={FontSize.Large} style={{ whiteSpace: "pre-line" }}>
            {workerData.worker.description}
          </Text>
        </Cell>
        <Cell>
          <LabelStyled style={{ marginTop: "12px" }}>
            {t("CompanyGroup.AssignedHourlyRate")}
          </LabelStyled>
          {workerData.defaultHourlyRate !== 0 ? (
            <Text
              fontSize={FontSize.Large}
              style={{ wordBreak: "break-all", marginBottom: "12px" }}
            >
              {`${workerData.defaultHourlyRate} ${t(
                "FindWorker.HourlyRateWithCurrency"
              )}`}
            </Text>
          ) : (
            <Text
              fontSize={FontSize.Large}
              color={Color.SeaBlue500}
              style={{ wordBreak: "break-all", marginBottom: "12px" }}
            >
              {t("CompanyGroup.NoDefaultRate")}
            </Text>
          )}

          <LabelStyled style={{ marginTop: "12px" }}>
            {t("CompanyGroup.Labels")}
          </LabelStyled>
          <LabelContainer>
            {workerData.labels &&
              workerData.labels.map((label: any) => (
                <Text
                  backgroundColor={Color.SeaBlue400}
                  padding
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Small}
                  color={Color.White}
                  style={{ marginLeft: "4px" }}
                >
                  {label.label}
                </Text>
              ))}
          </LabelContainer>
          <LabelStyled>{t("CompanyGroup.Comment")}</LabelStyled>
          <Text
            fontSize={FontSize.Large}
            style={{ whiteSpace: "pre-line", marginBottom: "12px" }}
          >
            {workerData.comment}
          </Text>
        </Cell>
        {(workerData.response === "None" ||
          workerData.response === "NoResponse" ||
          workerData.response === "Validated") && (
          <EditButtonContainer>
            <ButtonStyled
              backgroundColor={Color.MidnightBlue}
              onClick={() => {
                setShowSettingsModal(true);
              }}
              icon
            >
              {t("General.Edit")}
              <CustomIcon
                name="settings"
                color={Color.White}
                style={{ marginLeft: "4px" }}
              />
            </ButtonStyled>
          </EditButtonContainer>
        )}
        {!workerData.isValidated &&
          workerData.requestType === "WorkerRequest" && (
            <ButtonContainer>
              <ButtonStyled
                backgroundColor={Color.Destructive}
                onClick={() => {
                  updateEmployeeStatus("Reject");
                }}
              >
                {t("GigsCompany.Reject")}
              </ButtonStyled>
              <ButtonStyled
                backgroundColor={Color.BurntSienna}
                onClick={() => {
                  updateEmployeeStatus("Verify");
                }}
              >
                {t("CompanyGroup.Verify")}
              </ButtonStyled>
            </ButtonContainer>
          )}
      </ProfileGrid>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>{renderModalBody()}</ModalBody>
      </Modal>
      <WorkpoolWorkerSettings
        employeeData={workerData}
        setShowModal={setShowSettingsModal}
        showModal={showSettingsModal}
      />
    </div>
  );
};
