import { ChronoUnit, Instant, LocalDateTime, LocalTime } from "@js-joda/core";
import { TFunction } from "i18next";
import { CompanyOBSetting, CompanySettingDto } from "model/Company";
import { CompanyGigDto, GigStatus, GigType } from "model/Gig";
import { ShiftDto, ShiftRequestDto } from "model/Shift";
import {
  TimeReportDto,
  TimeReportResolution,
  TimeReportStatus,
  WorkerWithTimeReports,
} from "model/TimeReport";
import { ExportToCsv } from "export-to-csv";
import moment, { Moment } from "moment";
import { getCostsTimeReports } from "web-apps/company/utils/utils";
import axios from "axios";

type MenuItem = {
  text: string;
  path: string;
  image: string;
};

export const getMenuList = (
  userType: string | null,
  t: TFunction
): MenuItem[] => {
  switch (userType) {
    case "Company":
      return [
        {
          text: t("CompanyOverview.Overview"),
          path: "/company/dashboard",
          image: "eye",
        },
        {
          text: t("GigsCompany.Gigs"),
          path: "/company/gigs",
          image: "document",
        },
        {
          text: t("CalendarCompany.Calendar"),
          path: "/company/calendar",
          image: "calendar",
        },
        {
          text: t("ApplicationsCompany.Applications"),
          path: "/company/applications",
          image: "idbadge",
        },
        {
          text: t("CompanyGroup.Workpool"),
          path: "/company/work-pool",
          image: "idbadge",
        },
        {
          text: t("CompanyTimeReport.TimeReports"),
          path: "/company/time-reports",
          image: "clock",
        },
      ];
    case "Employee":
      return [
        {
          text: t("CompanyEmployee.Schedule"),
          path: "/employee/schedule",
          image: "calendar",
        },

        {
          text: t("CompanyProfile.Profile"),
          path: "/employee/profile",
          image: "person",
        },
      ];
    case "CompanyTimeStamp":
      return [];
    default:
      return [];
  }
};

export const isWithinXMinutes = (
  time1: Date,
  time2: Date,
  minutes: number
): boolean =>
  Math.abs(time1.getTime() - time2.getTime()) <= minutes * 60 * 1000;

export const getWorkHours = (
  t: TFunction,
  ...shifts: ShiftRequestDto[] | ShiftDto[] | TimeReportDto[]
): string => {
  let totalMinutes: number = 0;

  try {
    for (const shift of shifts) {
      const endTime = LocalDateTime.ofInstant(Instant.parse(shift.endTime));
      const startTime = LocalDateTime.ofInstant(Instant.parse(shift.startTime));
      totalMinutes += startTime.until(endTime, ChronoUnit.MINUTES);
      if (shift.breakStartTime && shift.breakEndTime) {
        totalMinutes -= calculateBreakTime(
          shift.breakStartTime,
          shift.breakEndTime,
          moment(
            LocalDateTime.ofInstant(Instant.parse(shift.startTime)).toString()
          ),
          moment(
            LocalDateTime.ofInstant(Instant.parse(shift.endTime)).toString()
          )
        );
      } else if ("breakTime" in shift && shift.breakTime) {
        totalMinutes -= (shift as any).breakTime;
      }
    }
  } catch {
    return "INVALID WORK HOURS";
  }

  const workMinutes = totalMinutes % 60;
  const workHours = Math.floor(totalMinutes / 60);

  const output = `${workHours ? workHours + t("General.HourShortened") : ""} ${
    workMinutes ? workMinutes + t("General.MinutesShortened") : ""
  }`;

  return output;
};

export const getShiftHours = (
  ...shifts: ShiftRequestDto[] | ShiftDto[] | TimeReportDto[]
): number => {
  try {
    let totalMinutes: number = 0;

    for (const shift of shifts) {
      const endTime = LocalDateTime.ofInstant(Instant.parse(shift.endTime));
      const startTime = LocalDateTime.ofInstant(Instant.parse(shift.startTime));
      if (shift.breakEndTime && shift.breakStartTime) {
        totalMinutes -= calculateBreakTime(
          shift.breakStartTime,
          shift.breakEndTime,
          moment(
            LocalDateTime.ofInstant(Instant.parse(shift.startTime)).toString()
          ),
          moment(
            LocalDateTime.ofInstant(Instant.parse(shift.endTime)).toString()
          )
        );
      } else if (shift.breakTime) {
        totalMinutes -= Number(shift.breakTime);
      }

      totalMinutes += startTime.until(endTime, ChronoUnit.MINUTES);
    }

    return totalMinutes / 60;
  } catch {
    return 0;
  }
};

export const getDateString = (time: string): string => {
  try {
      const date = new Date(time);
      if (isNaN(date.getTime())) throw new Error();

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = String(date.getUTCFullYear()).slice(2, 4);

      return `${day}/${month}/${year}`;
  } catch {
      return "INVALID DATE";
  }
};

export const getWeekDayString = (time: string) => {
  try {
    const date = LocalDateTime.ofInstant(Instant.parse(time));
    const dayOfWeek = date.dayOfWeek().name().slice(0, 3);
    const dayOfMonth = date.dayOfMonth();
    const month = date.monthValue();

    return `${dayOfWeek} ${dayOfMonth}/${month}`;
  } catch {
    return "INVALID WEEK DATE";
  }
};

export const getTimeString = (time: string) => {
  try {
    const date = LocalDateTime.ofInstant(Instant.parse(time));
    const hour = date.hour() < 10 ? "0" + date.hour() : date.hour();
    const minute = date.minute() < 10 ? "0" + date.minute() : date.minute();
    return `${hour}:${minute}`;
  } catch {
    return "INVALID TIME";
  }
};

export const formatDuration = (duration: string) => {
  const durationMoment = moment.duration(duration);
  const totalHours = Math.floor(durationMoment.asHours());
  const minutes = durationMoment.minutes();
  return `${totalHours}h ${minutes}min`;
};

export const paginate = (
  data: Array<any>,
  pageSize: number,
  pageNumber: number
) => {
  return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const getGigStatus = (gigData: CompanyGigDto) => {
  if (gigData.status === GigStatus.CLOSED) {
    return "Closed";
  } else {
    if (
      gigData.status === GigStatus.CREATED &&
      Date.now() > Date.parse(gigData.shifts[gigData.shifts.length - 1].endTime)
    ) {
      return "Passed";
    } else if (
      Date.now() > Date.parse(gigData.shifts[0].startTime) &&
      Date.now() < Date.parse(gigData.shifts[gigData.shifts.length - 1].endTime)
    ) {
      return "Ongoing";
    } else if (
      gigData.status === GigStatus.CREATED &&
      Date.now() <=
        Date.parse(gigData.shifts[gigData.shifts.length - 1].endTime)
    ) {
      return "ComingUp";
    }
  }
};

export const sortShifts = (shifts: ShiftDto[]) => {
  const sortedShifts = shifts.sort(
    (a, b) => Date.parse(a.startTime) - Date.parse(b.startTime)
  );

  return sortedShifts;
};

export function calculateNewRates(
  shifts: ShiftDto[] | ShiftRequestDto[] | TimeReportDto[],
  obArray: CompanyOBSetting[],
  minRate: number,
  maxRate?: number
) {
  let totalCostMin = getShiftHours(...shifts) * minRate;
  let totalCostMax = getShiftHours(...shifts) * (maxRate ? maxRate : minRate);
  let overlappingSettings: CompanyOBSetting[] = [];
  let totalObMin = 0;
  let totalObMax = 0;
  let costs: any = {
    totalCostMin,
    totalCostMax,
    overlappingSettings: overlappingSettings,
    totalObMin,
    totalObMax,
    minRate,
    maxRate,
  };
  shifts.forEach((shift: any) => {
    let times = null;
    if (!shift.deleteEntry) {
      let shiftStringStart = new Date(shift.startTime).toISOString();
      const matchingDate = obArray.find(
        (setting) => setting.date === shiftStringStart.split("T")[0]
      );
      if (matchingDate && matchingDate !== undefined) {
        times = setOverlapTimes(
          setStartTimes(shift, matchingDate),
          matchingDate
        );
        if (times.overlapStart < times.overlapEnd && times.sameDay) {
          if (
            !overlappingSettings.some(
              (setting) => setting.id === matchingDate.id
            )
          ) {
            overlappingSettings.push(matchingDate);
          }
          let durationHours = calculateObDuration(
            times.overlapStart,
            times.overlapEnd,
            times.shiftStartTime,
            times.shiftEndTime,
            times.obStartTime,
            times.obEndTime,
            calculateBreakTime(
              shift.breakStartTime,
              shift.breakEndTime,
              moment(
                LocalDateTime.ofInstant(
                  Instant.parse(shift.startTime)
                ).toString()
              ),
              moment(
                LocalDateTime.ofInstant(Instant.parse(shift.endTime)).toString()
              )
            )
          );

          costs = calculateTotalCost(durationHours, matchingDate, costs);
        }
      } else {
        obArray.forEach((ob) => {
          times = setOverlapTimes(setStartTimes(shift, ob), ob);

          if (times.overlapStart < times.overlapEnd && times.sameDay) {
            if (!overlappingSettings.some((setting) => setting.id === ob.id)) {
              overlappingSettings.push(ob);
            }
            let durationHours = calculateObDuration(
              times.overlapStart,
              times.overlapEnd,
              times.shiftStartTime,
              times.shiftEndTime,
              times.obStartTime,
              times.obEndTime,
              calculateBreakTime(
                shift.breakStartTime,
                shift.breakEndTime,
                moment(
                  LocalDateTime.ofInstant(
                    Instant.parse(shift.startTime)
                  ).toString()
                ),
                moment(
                  LocalDateTime.ofInstant(
                    Instant.parse(shift.endTime)
                  ).toString()
                )
              )
            );

            costs = calculateTotalCost(durationHours, ob, costs);
          }
        });
      }
    }
  });

  return {
    totalCostMin: costs.totalCostMin,
    totalCostMax: costs.totalCostMax,
    obSettings: costs.overlappingSettings,
    totalObMin: costs.totalObMin,
    totalObMax: costs.totalObMax,
  };
}

const setStartTimes = (
  shift: ShiftDto | ShiftRequestDto | TimeReportDto,
  ob: CompanyOBSetting
) => {
  let timeOffset = new Date(shift.startTime).getTimezoneOffset();
  let shiftStringStart = new Date(
    new Date(shift.startTime).valueOf() - timeOffset * 60000
  ).toISOString();
  let shiftStringEnd = new Date(
    new Date(shift.endTime).valueOf() - timeOffset * 60000
  ).toISOString();
  const shiftStart = new Date(shiftStringStart);
  const shiftEnd = new Date(shiftStringEnd);
  let shiftStartTime = new Date(`1972-02-01T${shiftStringStart.split("T")[1]}`);
  shiftStartTime.setHours(parseInt(shiftStringStart.split("T")[1]));
  shiftStartTime.setDate(1);
  let shiftEndTime = new Date(`1972-02-01T${shiftStringEnd.split("T")[1]}`);
  shiftEndTime.setHours(parseInt(shiftStringEnd.split("T")[1]));
  shiftEndTime.setDate(1);

  let isDay2 = false;
  if (shiftStringEnd.split("T")[0] !== shiftStringStart.split("T")[0]) {
    shiftEndTime.setDate(2);
    isDay2 = true;
  }

  let obStartTime;
  let obEndTime;
  let obIsDay2 = false;
  if (
    isDay2 &&
    new Date(`1972-02-02T${shiftStringEnd.split("T")[1]}`) >
      new Date(`1972-02-02T${ob.startTime}`) &&
    ob.weekday === shiftEnd.getDay()
  ) {
    obIsDay2 = true;
  }
  if (ob.startTime === "00:00:01") {
    obStartTime = new Date(`1972-02-02T${ob.startTime}`);
    obStartTime.setHours(
      parseInt(ob.startTime.split(":")[0]),
      parseInt(ob.startTime.split(":")[1])
    );
    obStartTime.setDate(2);
    obEndTime = new Date(`1972-02-02T${ob.endTime}`);
    obEndTime.setHours(
      parseInt(ob.endTime.split(":")[0]),
      parseInt(ob.endTime.split(":")[1])
    );
    obEndTime.setDate(2);
  } else {
    obStartTime = new Date(`1972-02-0${obIsDay2 ? "2" : "1"}T${ob.startTime}`);
    obStartTime.setHours(
      parseInt(ob.startTime.split(":")[0]),
      parseInt(ob.startTime.split(":")[1])
    );
    obEndTime = new Date(`1972-02-0${obIsDay2 ? "2" : "1"}T${ob.endTime}`);
    obEndTime.setHours(
      parseInt(ob.endTime.split(":")[0]),
      parseInt(ob.endTime.split(":")[1])
    );
    obIsDay2 ? obEndTime.setDate(2) : obEndTime.setDate(1);
    obIsDay2 ? obStartTime.setDate(2) : obStartTime.setDate(1);
  }

  return {
    shiftStringStart,
    shiftStringEnd,
    obStartTime,
    obEndTime,
    shiftStartTime: shiftStartTime,
    shiftEndTime: shiftEndTime,
    shiftStart,
    shiftEnd,
    obIsDay2,
  };
};

const setOverlapTimes = (times: any, ob: CompanyOBSetting) => {
  const overlapStart: any =
    times.shiftStartTime >= times.obStartTime
      ? times.shiftStartTime
      : times.obStartTime;
  const overlapEnd: any =
    times.shiftEndTime <= times.obEndTime
      ? times.shiftEndTime
      : times.obEndTime;
  let sameDay = false;
  if (ob.startTime === "00:00:01" || times.obIsDay2) {
    sameDay =
      ob.weekday === times.shiftEnd.getDay() ||
      times.shiftStringStart.split("T")[0] === ob.date;
  } else {
    sameDay =
      ob.weekday === times.shiftStart.getDay() ||
      times.shiftStringStart.split("T")[0] === ob.date;
  }
  return { ...times, overlapStart, overlapEnd, sameDay };
};

const calculateObDuration = (
  overlapStart: any,
  overlapEnd: any,
  shiftStartTime: any,
  shiftEndTime: any,
  obStartTime: any,
  obEndTime: any,
  minBreak: any
) => {
  let overlapStartDiff = Math.max(
    shiftStartTime.getTime(),
    obStartTime.getTime()
  );

  let overlapEndDiff = Math.min(shiftEndTime.getTime(), obEndTime.getTime());
  if (overlapStart > overlapEnd) {
    return 0;
  }

  let duration = overlapEndDiff - overlapStartDiff;
  let durationWithoutBreak = duration - minBreak * 60000;
  let durationHours = durationWithoutBreak / (1000 * 60 * 60);
  durationHours = parseFloat(durationHours.toFixed(3));

  if (durationHours.toString().endsWith(".999")) {
    durationHours = Math.round(durationHours);
  }
  return durationHours;
};

const calculateTotalCost = (
  durationHours: number,
  ob: CompanyOBSetting,
  costs: any
) => {
  if (ob.fixedValue) {
    costs.totalCostMin += ob.fixedValue * durationHours;
    costs.totalCostMax += ob.fixedValue * durationHours;
    costs.totalObMin += ob.fixedValue * durationHours;
    costs.totalObMax += ob.fixedValue * durationHours;
  } else if (ob.percentValue) {
    costs.totalCostMin +=
      ob.percentValue * 0.01 * (costs.minRate * durationHours);
    costs.totalCostMax +=
      ob.percentValue *
      0.01 *
      (costs.maxRate ? costs.maxRate : costs.minRate * durationHours);
    costs.totalObMin += ob.percentValue * 0.01 * costs.minRate * durationHours;
    costs.totalObMax +=
      ob.percentValue *
      0.01 *
      (costs.maxRate ? costs.maxRate : costs.minRate) *
      durationHours;
  }
  costs.totalCostMin = parseFloat(costs.totalCostMin.toFixed(2));
  costs.totalCostMax = parseFloat(costs.totalCostMax.toFixed(2));
  costs.totalObMin = parseFloat(costs.totalObMin.toFixed(2));
  costs.totalObMax = parseFloat(costs.totalObMax.toFixed(2));

  return {
    ...costs,
    totalCostMin: costs.totalCostMin,
    totalCostMax: costs.totalCostMax,
    totalObMin: costs.totalObMin,
    totalObMax: costs.totalObMax,
  };
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const updateTimeReportInContext = (
  context: WorkerWithTimeReports[],
  type:
    | "UPDATE_UNHANDLED_TIME_REPORTS"
    | "UPDATE_MONTHLY_TIME_REPORTS"
    | "UPDATE_GIG_TIME_REPORTS",
  newTimeReport: TimeReportDto
) => {
  const workerIndex = context.findIndex(
    (workerWithTimeReport) =>
      workerWithTimeReport.id === newTimeReport.workerId
  );
  let newTimeReportWorkers = context;

  if (workerIndex !== -1) {
    if (
      context[workerIndex].timeReports.find(
        (timeReport: TimeReportDto) => newTimeReport.id === timeReport.id
      )
    ) {
      let newTimeReports = [];
      if (
        type === "UPDATE_MONTHLY_TIME_REPORTS" ||
        type === "UPDATE_GIG_TIME_REPORTS"
      ) {
        newTimeReports = context[workerIndex].timeReports.map(
          (timeReport: TimeReportDto) =>
            timeReport.id === newTimeReport.id ? newTimeReport : timeReport
        );
      } else {
        newTimeReports = context[workerIndex].timeReports.filter(
          (timeReport: TimeReportDto) => timeReport.id !== newTimeReport.id
        );
      }

      if (newTimeReports.length === 0) {
        newTimeReportWorkers.splice(workerIndex, 1);
      } 
    }
  }
  return newTimeReportWorkers;
};

export const addLeadingZero = (
  digit: number,
  lessThan: number = 10
): string => {
  return (digit < lessThan ? "0" : "") + digit;
};

export const calculateCosts = (
  timeReports: TimeReportDto[],
  obSettings: CompanyOBSetting[],
  companySettings: CompanySettingDto[]
) => {
  let rates: any = [];
  let totalSalary = 0;
  let totalSocialFees = 0;
  let totalOb = 0;
  let totalCost = 0;
  let totalSimflexityFee = 0;
  timeReports.map((timeReport: TimeReportDto) => {
    let newRates = calculateNewRates(
      [timeReport],
      obSettings,
      timeReport.hourlyRate
    );

    rates.push(newRates);
    totalSimflexityFee += getSimflexityFee(
      [timeReport],
      companySettings,
      newRates.totalCostMin
    );
  });
  rates.map((rate: any) => {
    totalSalary += rate.totalCostMin;
    totalSocialFees += rate.totalCostMin * 0.3142;
    totalOb += rate.totalObMin;
  });
  totalCost = totalSalary + totalSocialFees + totalSimflexityFee;

  return {
    totalCost: parseFloat(totalCost.toFixed(2)),
    totalSalary: parseFloat(totalSalary.toFixed(2)),
    totalSocialFees: parseFloat(totalSocialFees.toFixed(2)),
    totalOb: parseFloat(totalOb.toFixed(2)),
    totalSimflexityFee: parseFloat(totalSimflexityFee.toFixed(2)),
  };
};

const getTableOneOptionsSingleWorker = (fileName: string) => {
  return {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "TimeReports",
    filename: fileName,
    useTextFile: false,
    useBom: true,
    headers: [
      "Role",
      "Date",
      "Shift",
      "Break",
      "Break Decimal",
      "Time",
      "Time Decimal",
      "Status",
      "Resolution",
      "Cost",
    ],
  };
};

const getTableTwoOptionsSingleWorker = (
  table_one_csv: string,
  fileName: string
) => {
  return {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: table_one_csv,
    filename: fileName,
    useTextFile: false,
    useBom: true,
    headers: [
      "Total cost",
      "Total salary",
      "Social Fees",
      "Total OB",
      "Simflexity Fee",
      "Total time",
      "Total Time Decimal",
      "Approved cost",
      "Approved time",
      "Approved time Decimal",
    ],
  };
};

export const convertTimeToDecimal = (timeString: string): number => {
  timeString = timeString.trim();

  if (timeString === "") {
    return 0;
  }

  const timePattern = /(\d+)\s*[ht](?:\s*(\d+)\s*min?)?|(\d+)\s*min/;
  const match = timeString.match(timePattern);

  if (!match) {
    const message = `convertTimeToDecimal, Invalid time format: ${timeString}`;
    console.log(message);
    throw new Error(message);
  }

  const hours = match[1] ? parseInt(match[1], 10) : 0;
  const minutes = match[2]
    ? parseInt(match[2], 10)
    : match[3]
    ? parseInt(match[3], 10)
    : 0;

  const decimalTime = hours + minutes / 60;
  return parseFloat(decimalTime.toFixed(2));
};

export const exportToCSV = async (
  workerTimeReportData: WorkerWithTimeReports,
  fileName: string,
  companyId: number,
  t: TFunction
) => {
  const timeReports: any[] = [];
  await Promise.all(
    workerTimeReportData.timeReports.map(async (timeReport: any) => {
      const startTime = LocalDateTime.ofInstant(
        Instant.parse(timeReport.startTime)
      );
      const endTime = LocalDateTime.ofInstant(
        Instant.parse(timeReport.endTime)
      );
      const weekDayString = `WeekDays.${startTime.dayOfWeek().name()}SHORT`;
      const costs = await getCostsTimeReports([timeReport], companyId).then(
        (res) => res
      );

      const timeInPrettyFormat = getWorkHours(t, timeReport);
      const timeInDecimal = convertTimeToDecimal(timeInPrettyFormat);
      const breakInPrettyFormat =
        (timeReport.breakStartTime && timeReport.breakEndTime
          ? calculateBreakTime(
              timeReport.breakStartTime,
              timeReport.breakEndTime,
              moment(
                LocalDateTime.ofInstant(
                  Instant.parse(timeReport.startTime)
                ).toString()
              ),
              moment(
                LocalDateTime.ofInstant(
                  Instant.parse(timeReport.endTime)
                ).toString()
              )
            )
          : 0) +
        " " +
        "min";
      const breakInDecimal = convertTimeToDecimal(breakInPrettyFormat);

      timeReports.push({
        role: timeReport.gigRole,
        date: `${t(
          weekDayString
        )} ${startTime.dayOfMonth()}/${startTime.monthValue()}`,
        shift: `${addLeadingZero(startTime.hour())}:${addLeadingZero(
          startTime.minute()
        )} - ${addLeadingZero(endTime.hour())}:${addLeadingZero(
          endTime.minute()
        )}`,
        break: breakInPrettyFormat,
        breakDec: breakInDecimal,
        time: timeInPrettyFormat,
        timeDec: timeInDecimal,
        status:
          timeReport.status === TimeReportStatus.NEW
            ? "Unsubmitted"
            : timeReport.status,
        resolution: timeReport.resolution,
        totalCost: costs.totalCost,
      });
    })
  );

  const table_one_options = getTableOneOptionsSingleWorker(fileName);

  const csvExporter_one = new ExportToCsv(table_one_options);
  var table_one_csv = csvExporter_one.generateCsv(timeReports, true);

  const approvedTimeReports: TimeReportDto[] =
    workerTimeReportData.timeReports.filter(
      (timeReport: any) =>
        timeReport.status === TimeReportStatus.APPROVED ||
        timeReport.status === TimeReportStatus.INVOICE_NOT_PAID ||
        timeReport.status === TimeReportStatus.PROCESSING_PAYMENT ||
        timeReport.status === TimeReportStatus.PAYMENT_STARTED ||
        timeReport.status === TimeReportStatus.PAID ||
        (timeReport.status === TimeReportStatus.CLOSED &&
          (timeReport.resolution === TimeReportResolution.TIME_REPORTED ||
            timeReport.resolution === TimeReportResolution.PAID ||
            timeReport.resolution === TimeReportResolution.PAYONDEMAND))
    );

  const table_two_options = getTableTwoOptionsSingleWorker(
    table_one_csv,
    fileName
  );

  const csvExporter_two = new ExportToCsv(table_two_options);
  const allCosts = await getCostsTimeReports(
    workerTimeReportData.timeReports,
    companyId
  );
  const approvedCosts = await getCostsTimeReports(
    approvedTimeReports,
    companyId
  );

  const totalTime = getWorkHours(t, ...workerTimeReportData.timeReports);
  const totalDecimalTime = convertTimeToDecimal(totalTime);
  const approvedTimePrettyFomat = getWorkHours(t, ...approvedTimeReports);
  const approvedTimeDecimal = convertTimeToDecimal(approvedTimePrettyFomat);

  let totalCostsAndTime = {
    totalCost: allCosts.totalCost,
    totalSalary: allCosts.grossSalary,
    socialFees: allCosts.socialFee,
    totalOB: allCosts.obAmount,
    simflexityFee: allCosts.simflexityFee,
    totalTime: totalTime,
    totalDecimalTime: totalDecimalTime,
    approvedCost: approvedCosts.totalCost,
    approvedTime: approvedTimePrettyFomat,
    approvedTimeDec: approvedTimeDecimal,
  };

  csvExporter_two.generateCsv([totalCostsAndTime]);
};

export const exportAllToCSV = async (
  workersTimeReportDataList: WorkerWithTimeReports[],
  fileName: string,
  t: TFunction,
  companyId: number
) => {
  let totalCSV = "";
  await Promise.all(
    workersTimeReportDataList.map(async (workerTimeReportData) => {
      const timeReports: any[] = [];
      await Promise.all(
        workerTimeReportData.timeReports.map(async (timeReport) => {
          const startTime = LocalDateTime.ofInstant(
            Instant.parse(timeReport.startTime)
          );
          const endTime = LocalDateTime.ofInstant(
            Instant.parse(timeReport.endTime)
          );
          const weekDayString = `WeekDays.${startTime.dayOfWeek().name()}SHORT`;
          const costs = await getCostsTimeReports([timeReport], companyId).then(
            (res) => res
          );

          const breakTf =
            (timeReport.breakStartTime && timeReport.breakEndTime
              ? calculateBreakTime(
                  timeReport.breakStartTime,
                  timeReport.breakEndTime,
                  moment(
                    LocalDateTime.ofInstant(
                      Instant.parse(timeReport.startTime)
                    ).toString()
                  ),
                  moment(
                    LocalDateTime.ofInstant(
                      Instant.parse(timeReport.endTime)
                    ).toString()
                  )
                )
              : 0) +
            " " +
            "min";
          const breakDec = convertTimeToDecimal(breakTf);
          const timeTf = getWorkHours(t, timeReport);
          const timeDec = convertTimeToDecimal(timeTf);

          timeReports.push({
            role: timeReport.gigRole,
            date: `${t(
              weekDayString
            )} ${startTime.dayOfMonth()}/${startTime.monthValue()}`,
            shift: `${addLeadingZero(startTime.hour())}:${addLeadingZero(
              startTime.minute()
            )} - ${addLeadingZero(endTime.hour())}:${addLeadingZero(
              endTime.minute()
            )}`,
            break: breakTf,
            breakDec: breakDec,
            time: timeTf,
            timeDec: timeDec,
            status:
              timeReport.status === TimeReportStatus.NEW
                ? "Unsubmitted"
                : timeReport.status,
            resolution: timeReport.resolution,
            totalCost: costs.totalCost,
          });
        })
      );

      const table_one_options = {
        fieldSeparator: ";",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: `\n Time reports - ${workerTimeReportData.firstName} ${workerTimeReportData.lastName}`,
        filename: fileName,
        useTextFile: false,
        useBom: true,
        headers: [
          "Role",
          "Date",
          "Shift",
          "Break",
          "Break Decimal",
          "Time",
          "Time Decimal",
          "Status",
          "Resolution",
          "Cost",
        ],
      };

      const csvExporter_one = new ExportToCsv(table_one_options);
      var table_one_csv = csvExporter_one.generateCsv(timeReports, true);

      const approvedTimeReports: TimeReportDto[] =
        workerTimeReportData.timeReports.filter(
          (timeReport) =>
            timeReport.status === TimeReportStatus.APPROVED ||
            timeReport.status === TimeReportStatus.INVOICE_NOT_PAID ||
            timeReport.status === TimeReportStatus.PROCESSING_PAYMENT ||
            timeReport.status === TimeReportStatus.PAYMENT_STARTED ||
            timeReport.status === TimeReportStatus.PAID ||
            (timeReport.status === TimeReportStatus.CLOSED &&
              (timeReport.resolution === TimeReportResolution.TIME_REPORTED ||
                timeReport.resolution === TimeReportResolution.PAID ||
                timeReport.resolution === TimeReportResolution.PAYONDEMAND))
        );

      const table_two_options = {
        fieldSeparator: ";",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: `\n Total costs - ${workerTimeReportData.firstName} ${workerTimeReportData.lastName}`,
        filename: fileName,
        useTextFile: false,
        useBom: true,
        headers: [
          "Total cost",
          "Total salary",
          "Social Fees",
          "Total OB",
          "Simflexity Fee",
          "Total time",
          "Total time Decimal",
          "Approved cost",
          "Approved time",
          "Approved time decimal",
        ],
      };
      const csvExporter_two = new ExportToCsv(table_two_options);
      const allCosts = await getCostsTimeReports(
        workerTimeReportData.timeReports,
        companyId
      );
      const costsApproved = await getCostsTimeReports(
        approvedTimeReports,
        companyId
      );
      const totalTimeTf = getWorkHours(t, ...workerTimeReportData.timeReports);
      const totalTimeDec = convertTimeToDecimal(totalTimeTf);
      const approvedTimeTf = getWorkHours(t, ...approvedTimeReports);
      const approvedTimeDec = convertTimeToDecimal(approvedTimeTf);

      axios
        .all([allCosts, costsApproved])
        .then(axios.spread((all, approved) => {}));
      let totalCostsAndTime = {
        totalCost: allCosts.totalCost,
        totalSalary: allCosts.grossSalary,
        socialFees: allCosts.socialFee,
        totalOB: allCosts.obAmount,
        simflexityFee: allCosts.simflexityFee,
        totalTime: totalTimeTf,
        totalTimeDec: totalTimeDec,
        approvedCost: costsApproved.totalCost,
        approvedTime: approvedTimeTf,
        approvedTimeDec: approvedTimeDec,
      };

      const csv2 = csvExporter_two.generateCsv([totalCostsAndTime], true);

      totalCSV += table_one_csv + csv2;
    })
  );
  downloadCsv(totalCSV, fileName);
};

const downloadCsv = (data: any, fileName: string) => {
  let blob = new Blob([data], {
    type: `text/csv;charset=utf8;`,
  });

  // Create link element in the browser and set the download
  // attribute to the blob that was created.
  let link = document.createElement("a");
  link.download = `${fileName}.csv`;
  link.href = URL.createObjectURL(blob);

  // Ensure the link isn't visible to the user or cause layout shifts.
  link.setAttribute("visibility", "hidden");

  // Add to document body, click and remove it.
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getSimflexityFee = (
  timeReports: TimeReportDto[] | any,
  companySettings: CompanySettingDto[],
  totalCost: number,
  type?: GigType,
  shifts?: boolean
) => {
  let totalSimflexityFees = 0;

  for (let i = 0; i < timeReports.length; i++) {
    const gigType = type ? type : timeReports[i].gigType;
    let timeReportHours = getShiftHours(...[timeReports[i]]);
    let fee = 0;
    let stopLoop = false;
    if (gigType === GigType.PUBLIC || !gigType) {
      let feeInfo = findFee(
        {
          feeType: "MarketplaceFeeType",
          fee: "MarketplaceFee",
        },
        companySettings,
        timeReportHours,
        totalCost
      );
      fee = feeInfo.timeReportSimflexityFee;
      stopLoop = feeInfo.isPercentage;
    } else if (gigType === GigType.WORKPOOL) {
      let feeInfo = findFee(
        {
          feeType: "WorkpoolFeeType",
          fee: "WorkpoolFee",
        },
        companySettings,
        timeReportHours,
        totalCost
      );
      fee = feeInfo.timeReportSimflexityFee;
      stopLoop = feeInfo.isPercentage;
    } else if (gigType === GigType.HIDDEN) {
      let feeInfo = findFee(
        {
          feeType: "StaffingFeeType",
          fee: "StaffingFee",
        },
        companySettings,
        timeReportHours,
        totalCost
      );
      fee = feeInfo.timeReportSimflexityFee;
      stopLoop = feeInfo.isPercentage;
    } else if (gigType === GigType.STAFFING) {
      let feeInfo = findFee(
        {
          feeType: "StaffingFeeType",
          fee: "StaffingFee",
        },
        companySettings,
        timeReportHours,
        totalCost
      );
      fee = feeInfo.timeReportSimflexityFee;
      stopLoop = feeInfo.isPercentage;
    }
    totalSimflexityFees += fee;
    if (shifts && stopLoop) {
      break;
    }
  }
  return totalSimflexityFees;
};

const findFee = (
  feeType: { feeType: string; fee: string },
  companySettings: CompanySettingDto[],
  timeReportHours: number,
  totalCost: number
) => {
  let isPercentage = false;
  let timeReportSimflexityFee = 0;
  const type = companySettings.find(
    (setting) => setting.key === feeType.feeType
  );
  const simflexityFee = companySettings.find(
    (setting) => setting.key === feeType.fee
  );
  if (type && type.value === "Flat" && simflexityFee) {
    timeReportSimflexityFee = timeReportHours * parseFloat(simflexityFee.value);
  } else if (type && type.value === "Percentage" && simflexityFee) {
    timeReportSimflexityFee = totalCost * parseFloat(simflexityFee.value);
    isPercentage = true;
  }
  return { timeReportSimflexityFee, isPercentage };
};

export const calculateBreakDurationInMinutes = (
  startMoment: Moment,
  endMoment: Moment
) => {
  const duration = moment.duration(endMoment.diff(startMoment)).asMinutes();

  return Math.abs(Math.round(duration));
};

export const calculateBreakTime = (
  breakTimeStart: string,
  breakTimeEnd: string,
  startDate: Moment,
  endDate: Moment
) => {
  if (breakTimeEnd.length > 5 && breakTimeStart.length > 5) {
    const totalBreak =
      (new Date(breakTimeEnd).getTime() - new Date(breakTimeStart).getTime()) /
      60000;

    return Math.round(totalBreak);
  } else {
    if (breakTimeStart > breakTimeEnd) {
      const totalBreak =
        (new Date(
          `${moment(endDate).format("YYYY-MM-DD")}T${breakTimeEnd}:00`
        ).getTime() -
          new Date(
            `${moment(startDate).format("YYYY-MM-DD")}T${breakTimeStart}:00`
          ).getTime()) /
        60000;

      return Math.round(totalBreak);
    }
    //else if(endDate !== startDate )
    else {
      const totalBreak =
        (new Date(
          `${startDate.format("YYYY-MM-DD")}T${breakTimeEnd}:00`
        ).getTime() -
          new Date(
            `${startDate.format("YYYY-MM-DD")}T${breakTimeStart}:00`
          ).getTime()) /
        60000;

      return Math.round(totalBreak);
    }
  }
};

export const createBreakStrings = (
  startTime: string,
  endTime: string,
  breakStart: string,
  breakEnd: string,
  date: Moment,
  endDate: Moment
) => {
  let breakStartString = "";
  let breakEndString = "";
  if (
    moment(`${date.format("YYYY-MM-DD")}T${startTime}:00`) >
    moment(`${date.format("YYYY-MM-DD")}T${breakStart}:00`)
  ) {
    breakStartString = moment(
      `${endDate.format("YYYY-MM-DD")}T${breakStart}:00`
    ).toISOString();
  } else {
    breakStartString = moment(
      `${date.format("YYYY-MM-DD")}T${breakStart}:00`
    ).toISOString();
  }
  if (breakStart > breakEnd || (breakEnd <= endTime && breakEnd < startTime)) {
    breakEndString = moment(
      `${endDate.format("YYYY-MM-DD")}T${breakEnd}:00`
    ).toISOString();
  } else {
    breakEndString = moment(
      `${date.format("YYYY-MM-DD")}T${breakEnd}:00`
    ).toISOString();
  }

  return { breakStartString, breakEndString };
};
